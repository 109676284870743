<template>
    <div>
        <b-navbar class="no-network-header">
            <div class="container centered">
                <b-navbar-brand>
                    <router-link :to="{ path: '/' }">
                        <img
                            src="/logo.webp"
                            alt="pescario.ro"
                            height="32"
                            width="130"
                        >
                    </router-link>
                </b-navbar-brand>
            </div>
        </b-navbar>
        <div class="container">
            <b-row>
                <b-col
                    cols="12"
                    class="no-network"
                >
                    <h1>{{ $t('page.noNetwork.heading') }}</h1>
                </b-col>
            </b-row>
        </div>
        <div class="no-network-footer mt-4">
            <div class="footer-copyright">
                <p class="sm">
                    © {{ year }} Pescario, All rights reserved
                </p>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'NoNetwork',
        computed: {
            year(){
                let currentDate = new Date();
                return currentDate.getFullYear();
            }
        }
    };
</script>
<style scoped lang="scss">
.no-network {
  min-height: calc(100vh - 72px - 24px - 2px);
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  .no-network-header {
    box-shadow: inset 0px -1px 0px $gray-light;
    position: fixed;
    z-index: 999;
    background: $white;
    width: 100%;
  }
  h1 {
    position: absolute;
    color: $primary;
    text-align: center;
    margin: 0;
    top: 50%;
    @include media-breakpoint-between(sm, md) {
      font-size: 52px;
      max-width: 75%;
    }
    @include media-breakpoint-down(sm) {
      font-size: 40px;
      max-width: 90%;
    }
  }

  .no-network-footer {
    color: $gray;
    border-top: 1px solid $gray-thin;
    background-color: $white;
    position: relative;
    .footer-copyright {
      padding: 24px 0px;
      p {
        text-align: center;
        color: $gray-dark;
      }
    }
  }
}
</style>
